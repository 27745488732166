import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          Hot induction formed and cold extruded integral branches can be
          manufactured for high performance applications such as offshore
          production and test headers, pipeline branch connections, vessels etc.
        </p>
      </div>
    </div>
  )
}

export default PageContent
